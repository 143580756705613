import React from "react";
import { GreenButton } from "../Index1";
import "../styles.css";

function Index1() {
  return (
    <div className="App">
      <div className="container text-black font-ganh">
        <h1 className="font-ganh">
          ĐIÊN NẶNG #4
        </h1>
          <br />
          <br />
        <h3 className="font-ganh">
          Đây không phải nơi mua vé, vui lòng chuyển sang trang chính thức để mua vé.
        </h3>
        <br />
        <br />
        <GreenButton
            variant="contained"
            href="/info"
            className="w-1/2 main-button"
            >
                Quay về trang mua vé
        </GreenButton>
        </div>
    </div>
  );
}

export default Index1;
