import React, { useEffect, useState } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import PaymentFieldsTableDemo from "../components/PaymentFieldsTableDemo";
import OrderTableDemo from "../components/OrderTableDemo";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { getOrder } from "../api/payosApi";
import Header from "../components/Header";

export default function Result() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const paramsValue = new URLSearchParams(location.search);
    const orderCode = paramsValue.get("orderCode") || location.state?.orderCode;

    console.log("OrderCode:", orderCode); // Debugging line

    if (orderCode) {
      getOrder(orderCode)
        .then((data) => {
          console.log("Order Data:", data); // Debugging line
          if (data.error === 0) {
            setOrder(data);
          } else if (data.error === -1) {
            toast.warning("Không tìm thấy đơn hàng");
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Có lỗi xảy ra");
          setLoading(false);
        });
    } else {
      toast.error("Mã đơn hàng không hợp lệ");
      setLoading(false);
    }
  }, [location]);

  return (
    <Box
      component={"div"}
      sx={{ minHeight: "100vh", fontFamily: "GanhThin"}}
      className="h-full flex flex-col !content-center flex-wrap gap-5 bg-green-400 text-green-400 px-2"
    >
      {/* <Header /> */}
      <Box
        component="div"
        className="w-full pb-16 md:w-3/4"
        sx={{ alignSelf: "center" }}
      >
      <Box className="">
        <ToastContainer />
        {loading ? (
          <LinearProgress />
        ) : (
          <Box>
            <OrderTableDemo
              data={order?.data}
              existingTransaction={order?.existingTransaction}
              ticketPurchase={order?.ticketPurchase}
            />
            {/* <PaymentFieldsTableDemo data={order?.data?.webhook_snapshot} /> */}
          </Box>
        )}
      </Box>
      </Box>
      </Box>
  );
}
