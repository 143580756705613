import React, { useState } from "react";
import { Modal } from "antd";
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import "../styles.css"; // Ensure you have custom styles here

export const InfoButton = styled(Button)(({ theme }) => ({
  fontFamily: 'GanhThin',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: '#4ade80',
  marginTop: '1rem',
  padding: '0.5rem',
  '&:hover': {
    opacity: 0.9,
  },
}));

const CustomModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* Info Button */}
      <InfoButton variant="outlined" onClick={showModal}>
        Thông tin chi tiết
      </InfoButton>

      {/* Custom Modal */}
      <Modal
        title={<h2 className="custom-modal-title">Thông tin chi tiết</h2>}
        visible={isModalVisible}
        onOk={handleOk}
        footer={[
          <Button
            key="ok"
            onClick={handleOk}
            className="custom-ok-button"
          >
            OK
          </Button>,
        ]}
        className="custom-modal"
        centered
      >
        <div className="w-full text-white bg-black custom-modal-content">
          <h2 className="font-ganh">Điên Dại - Điên Nặng 4</h2>
          <p className="font-ganh">
            Vượt qua bão Yagi, đến lượt siêu bão Điên Nặng 4, nhạc giật cấp 100. Điên Nặng lần này có gì khác? Chính là âm nhạc!
          </p>
          <p className="font-ganh">
            Vẫn là Underground nhưng lại có nhiều màu sắc mới mẻ, đầy đủ từ Hiphop, Các loại House, UKG, Bass Music, Techno, thậm chí là PsyTrance,... trộn lẫn vào với nhau.
          </p>
          <p className="font-ganh">
            Vẫn là tiêu chí Private Party, Chỉ có khách mời và Limited duy nhất 150 tickets mở bán tại bữa tiệc dành cho Người Điên.
          </p>
          <p className="font-ganh">
            Với 21+ Nghệ sĩ, từ Hà Nội và Sài Gòn, gồm cả DJ, Rappers, MC, cùng hàng trăm người điên khác, đây sẽ là bữa tiệc lớn nhất của Điên Nặng từ trước đến nay.
          </p>
          <p className="font-bold text-green-500 font-ganh">
            Đã tới Điên Nặng là nhớ không được lái xe!
          </p>
          <h3 className="mt-2 font-ganh">Artist Lineup</h3>
          <p className="font-ganh"><strong className="font-ganh">DJ:</strong> C'Six, JxstZen, Tân Thiếu Gia, Orriganery, Juz Kevin, Steji, Luniic, Darksize, Lupin B, Titdayungboi, Dr. Mai Đích Loong, Kydz, YungCell</p>
          <p className="font-ganh"><strong className="font-ganh">MC:</strong> Unixerse, Bryan</p>
          <p className="font-ganh"><strong className="font-ganh">Rapper:</strong> CCMK, Cậu Phát, Sixtyuptown, SpideyBoy, Adam The Litder, TONIX</p>

          <h3 className="mt-2 font-ganh">Thông tin vé</h3>
          <p className="font-ganh"><strong className="font-ganh">GA Tickets:</strong> 399k/vé</p>

          <h3 className="mt-2">Về Điên Nặng 4</h3>
          <ul>
            <li className="font-ganh"><strong className="font-ganh">Thời gian:</strong> Thứ năm, 26. 9. 2024 - 20h30 đến khi điên.</li>
            <li className="font-ganh"><strong className="font-ganh">Địa điểm:</strong> Noxx Citi - 15 Lý Thường Kiệt, Hà Nội</li>
            <li className="font-ganh"><strong className="font-ganh">Dresscode:</strong> Mặc gì trông điên là được 👌</li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default CustomModal;
