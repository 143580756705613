import {
    TextField,
    Box,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
  } from "@mui/material";
  import React, { useState, useRef, useEffect } from "react";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import { useNavigate } from "react-router-dom";
  import { createPaymentLink, getCount } from "../api/payosApi";
  import useScript from "react-script-hook";
  import _1920_1080_2 from "../static/images/1920_1080_2.png";
  import { Image } from "antd";
  import { GreenButton } from "../Index1";
  import LoadingOverlay from "../components/LoadingOverlay"; // Import the LoadingOverlay component
  import ReCAPTCHA from "react-google-recaptcha";
  import "../styles.css";
  
  export default function Info() {
    const [capVal, setCapVal] = useState();
    const [totalAmount, setTotalAmount] = useState(399000); // State for total amount
    const [ticketCount, setTicketCount] = useState(1); // Initialize with 1
    const [availableTickets, setAvailableTickets] = useState(150); // State for available tickets
  
    const navigate = useNavigate();
    const [openDialogLoading, setOpenDialogLoading] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const nameRef = useRef("");
    const emailRef = useRef("");
    const phoneRef = useRef("");
    const birthDateRef = useRef("");
    const sexRef = useRef("");
    const ticketsRef = useRef(1); // Initialize ticketsRef with 1
    const recaptchaRef = useRef(null);
  
    const [loading, error] = useScript({
      src: process.env.REACT_APP_PAYOS_SCRIPT,
      checkForExisting: true,
    });
    const RETURN_URL = `${window.location.origin}/result/`;
    const CANCEL_URL = `${window.location.origin}/result/`;
  
    useEffect(() => {
      const fetchTicketCount = async () => {
        try {
          const response = await getCount();
          if (response.error === 0 && response.data.paidCount !== undefined) {
            const paidCount = response.data.paidCount;
            const available = 150 - paidCount;
            setAvailableTickets(available); // Set the number of available tickets
          } else {
            toast.error("Failed to fetch ticket count");
          }
        } catch (error) {
          console.error("Error fetching ticket count:", error);
          toast.error("Error fetching available tickets");
        }
      };
  
      fetchTicketCount();
    }, []); // Fetch ticket count when component mounts
  
    const handleTicketsChange = (event) => {
      let tickets = event.target.value === '' ? '' : Number(event.target.value);
  
      // Allow empty input, but still validate the number if present
      if (tickets !== '' && tickets < 1) {
        tickets = 1;
      }
  
      // If tickets exceed availableTickets, set to maximum allowed
      if (tickets > availableTickets) {
        toast.warning(`Số lượng vé không được vượt quá ${availableTickets}.`);
        tickets = availableTickets;
      }
  
      setTicketCount(tickets);
      setTotalAmount(tickets !== '' ? tickets * 399000 : 0); // Recalculate total amount
    };
  
    const handleBlur = () => {
      if (ticketCount === '' || ticketCount < 1) {
        toast.info("Số lượng vé phải từ 1 trở lên!");
        setTicketCount(1);
        setTotalAmount(399000);
      }
    };
  
    const validateInputs = () => {
      const name = nameRef.current?.value?.trim() || "";
      const email = emailRef.current?.value?.trim() || "";
      const phone = phoneRef.current?.value?.trim() || "";
      const birthDate = birthDateRef.current?.value?.trim() || "";
      const sex = sexRef.current?.value?.trim() || "";
      const tickets = Number(ticketsRef.current?.value) || 1;
  
      if (!name) return "Tên là bắt buộc";
      if (!email) return "Email là bắt buộc";
      if (!phone) return "Số điện thoại là bắt buộc";
      if (!birthDate) return "Ngày sinh là bắt buộc";
      if (!sex) return "Giới tính là bắt buộc";
      if (tickets <= 0 || tickets > availableTickets) {
        return `Số lượng vé phải từ 1 đến ${availableTickets}`;
      }
  
      if (!/^[\w-_.+]+@[\w-]+\.[a-zA-Z]{2,}$/.test(email))
        return "Định dạng email không hợp lệ";
      const localPhoneRegex = /^0\d{9}$/; // Local format: 10 digits starting with 0
      const internationalPhoneRegex = /^(?:\+84|84)\d{9}$/; // International format: 11 digits starting with +84 or 84
      if (!(localPhoneRegex.test(phone) || internationalPhoneRegex.test(phone))) {
        return "Số điện thoại không hợp lệ. Số điện thoại Việt Nam phải bắt đầu bằng 0 (10 chữ số) hoặc +84/84 (11 chữ số)";
      }
      if (!/^\d{4}-\d{2}-\d{2}$/.test(birthDate))
        return "Định dạng ngày sinh không hợp lệ";
  
      return null;
    };
  
    const getRecaptchaToken = async () => {
      if (recaptchaRef.current) {
        const token = await recaptchaRef.current.executeAsync();
        setCaptchaVerified(true); // Set captcha verification state
        return token;
      }
      return null;
    };
  
    const createPaymentLinkHandle = async function (
      callbackFunction,
      setLoading
    ) {
      setLoading(true);
      const validationError = validateInputs();
      if (validationError) {
        setLoading(false);
        toast.error(validationError);
        return;
      }
  
      if (!captchaVerified) {
        setLoading(false);
        toast.error("Vui lòng hoàn tất CAPTCHA.");
        return;
      }
  
      try {
        // Get reCAPTCHA token
        const recaptchaToken = await getRecaptchaToken();
  
        const body = JSON.stringify({
          name: nameRef.current.value.trim(),
          email: emailRef.current.value.trim(),
          phone: phoneRef.current.value.trim(),
          birthDate: birthDateRef.current.value.trim(),
          sex: sexRef.current.value.trim(),
          tickets: Number(ticketCount),
          description: "",
          productName: "",
          amount: totalAmount, // Use the total amount calculated
          returnUrl: RETURN_URL,
          cancelUrl: CANCEL_URL,
          recaptchaToken: recaptchaToken, // Add reCAPTCHA token to the request
        });
        console.log(body);
        let response = await createPaymentLink(body);
        console.log("🚀 ~ Info ~ response:", response);
        if (response.error !== 0) throw new Error("Call Api failed: ");
        callbackFunction(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
        toast.error("Có lỗi xảy ra", error.message);
      }
    };
  
    const openPaymentDialog = async function (checkoutResponse) {
      if (checkoutResponse) {
        let url = checkoutResponse.checkoutUrl;
        if (url.startsWith("https://dev.pay.payos.vn")) {
          url = url.replace(
            "https://dev.pay.payos.vn",
            "https://next.dev.pay.payos.vn"
          );
        } else if (url.startsWith("https://pay.payos.vn")) {
          url = url.replace("https://pay.payos.vn", "https://pay.payos.vn");
        }
  
        let { open } = window.PayOSCheckout.usePayOS({
          RETURN_URL: RETURN_URL,
          ELEMENT_ID: "config_root",
          CHECKOUT_URL: url,
          onExit: (eventData) => {
            console.log(eventData);
          },
          onSuccess: (eventData) => {
            console.log(eventData);
            window.location.href = `${RETURN_URL}?orderCode=${eventData.orderCode}`;
          },
          onCancel: (eventData) => {
            console.log(eventData);
            window.location.href = `${CANCEL_URL}?orderCode=${eventData.orderCode}`;
          },
        });
        open();
      }
    };
  
    const handleSubmit = async () => {
      const validationError = validateInputs();
      if (validationError) {
        toast.error(validationError);
        return; // Prevent submission if there are validation errors
      }
  
      setOpenDialogLoading(true);
      await createPaymentLinkHandle(openPaymentDialog, setOpenDialogLoading);
      setOpenDialogLoading(false);
    };
  
    return (
      <Box
        component={"div"}
        sx={{ minHeight: "100vh" }}
        className="flex flex-col !content-center flex-wrap gap-5 bg-black text-green-400 px-2 font-ganh"
      >
        <Box
          component="div"
          className="w-full p-4 pb-16 lg:p-0 lg:w-1/2"
          sx={{ alignSelf: "center" }}
        >
          <Image
            src={_1920_1080_2}
            preview={false}
            style={{ width: "100%", maxWidth: "1000px" }}
          />
         <div className="w-full my-10 text-white bg-black">
            <h2 className="text-green-400 font-ganh">Điên Dại - Điên Nặng 4</h2>
            <p className="font-ganh">
              Vượt qua bão Yagi, đến lượt siêu bão Điên Nặng 4, nhạc giật cấp 100. Điên Nặng lần này có gì khác? Chính là âm nhạc! 
              Vẫn là Underground nhưng lại có nhiều màu sắc mới mẻ, đầy đủ từ Hiphop, Các loại House, UKG, Bass Music, Techno, thậm chí là PsyTrance,... trộn lẫn vào với nhau.
            </p>
            <p className="font-ganh">
              Vẫn là tiêu chí Private Party, Chỉ có khách mời và Limited duy nhất 150 tickets mở bán tại bữa tiệc dành cho Người Điên.
            </p>
            <p className="font-ganh">
              Với 21+ Nghệ sĩ, từ Hà Nội và Sài Gòn, gồm cả DJ, Rappers, MC, cùng hàng trăm người điên khác, đây sẽ là bữa tiệc lớn nhất của Điên Nặng từ trước đến nay. 
              7 tiếng liên tục Party cùng đa dạng các thể loại âm nhạc miên, điểm đặc biệt tại số Điên Nặng lần này là: cùng điên, thoải mái loạn, miễn vui là được 👌.
            </p>
            <p className="mt-6 mb-1 text-2xl font-bold text-green-400 underline font-ganh">Đã tới Điên Nặng là nhớ không được lái xe!</p>

            <h3 className="mt-6 mb-1 text-green-400 font-ganh">Artist Lineup: 20+ Nghệ sĩ - từ Hà Nội - Hồ Chí Minh: </h3>
            <p className="font-ganh"><strong className="mr-4 text-lg italic font-ganh">(DJ)</strong> C'Six - Jxstzen - Tân Thiếu Gia - Orriganergy - Juz Kevin - Steji - Darksize - LupinB - titdayungboi - Dr. Mai Đích Loong - Kydz - YungCell</p>
            <p className="font-ganh"><strong className="mr-4 text-lg italic font-ganh">(MC)</strong> Unixerse - Bryan</p>
            <p className="font-ganh"><strong className="mr-4 text-lg italic font-ganh">(Rapper)</strong> CCMK - Cậu Phát - Sixtyuptown - SpideyBoy - Adam, The Litder - TONIX - Jaymou</p>

            <h3 className="mt-6 mb-1 text-green-400 font-ganh">Thông tin vé</h3>
            <p className="font-ganh"><strong className="mr-4 text-lg italic font-ganh">GA Tickets:</strong> 399k/vé</p> 
            <p className="font-ganh">bao gồm mix drinks không giới hạn, uống đến khi bất tỉnh</p>

            <h3 className="mt-6 mb-1 text-green-400 font-ganh">Về Điên Nặng 4</h3>
            <p className="font-ganh"><strong className="mr-4 text-lg italic font-ganh">Thời gian:</strong> Thứ năm, 26. 9. 2024 - 20h30 đến khi điên.</p>
            <p className="font-ganh"><strong className="mr-4 text-lg italic font-ganh">Địa điểm:</strong> Noxx Citi - 15 Lý Thường Kiệt, Hà Nội</p>
            <p className="font-ganh"><strong className="mr-4 text-lg italic font-ganh">Dresscode:</strong> Mặc gì trông điên là được 👌</p>

            {/* <h3 className="mt-6 mb-1 text-green-400 font-ganh">Lưu ý</h3>
            <p className="font-ganh">Không thay đổi nội dung ck.</p>
            <p className="font-ganh">Sau khi thanh toán thì phải quay lại trang thanh toán để hoàn thiện việc xác nhận.</p>
            <p className="font-ganh">Trong trường hợp không thấy mail về, hãy check mục spam và thông báo đến Điên Nặng để giải quyết.</p> */}
          </div>
          <Box component="div" sx={{ width: "100%" }}>
            <GreenButton
            href="/orders"
            className="w-full font-3xl"
            variant="contained"
            disabled
            >
            HẸN GẶP BẠN Ở ĐIÊN NẶNG PARTY 😈
            </GreenButton>
          </Box>
        </Box>
          {/* <Image
            src={_1920_1080_2}
            preview={false}
            style={{ width: "100%", maxWidth: "1000px" }}
          /> */}
        {/* <Image src={_1920_1080} preview={false} style={{ width: '100%'}}/> */}
      </Box>
    );
  }
  