import { Provider } from "react-redux";
import { store } from "./redux/store";
import DemoPayOS from "./pages/DemoPayOS";
import Result from "./pages/Result";
import Payment from "./pages/Payment";
import Index1 from "../src/Index1";
import Info from "./pages/Info";
import { Box, ThemeProvider } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Error from "./pages/Error"; // Import your error page component

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index1 />,
  },
  {
    path: "/info",
    element: <Info />,
  },
  // {
  //   path: "/orders",
  //   element: <DemoPayOS />,
  // },
  // {
  //   path: "/payment",
  //   element: <Payment />,
  // },
  {
    path: "/result",
    element: <Result />,
  },
  {
    path: "*", // This matches all undefined routes
    element: <Error />, // Render the error page
  },
]);

const Routes = () => {
  const theme = useSelector((state) => state.theme.mode);
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          minHeight: "100vh",
          fontFamily: 'GanhThin'
        }}
        component={"div"}
      >
        <RouterProvider router={router} />
      </Box>
    </ThemeProvider>
  );
};

export default App;
