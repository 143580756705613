import React, { useState } from "react";
import Preloader from "./components/Preloader/Preloader";
import { styled } from '@mui/material/styles';
import { Image, Modal } from 'antd';
import { Button } from "@mui/material";
import kvchinh from "./static/images/kvchinh.png";
import _1920_1080_3 from "./static/images/1920_1080_3.png";
import CustomModal from "./components/CustomModal";
import "./styles.css";

export const GreenButton = styled(Button)(({ theme }) => ({
  fontFamily: 'GanhThin',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: 'black',
  backgroundColor: '#4ade80',
  marginTop: '1rem',
  padding: '0.5rem',
  '&:hover': {
    opacity: 0.9,
    backgroundColor: '#4ade80',
  },
  '&.Mui-disabled': {
    backgroundColor: '#4ade80',  // Set a disabled background color
    color: 'black',  // Set a disabled text color
    opacity: 0.7,  // Adjust opacity
  },
}));

export const InfoButton = styled(Button)(({ theme }) => ({
  fontFamily: 'GanhThin',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: '#4ade80',
  marginTop: '1rem',
  padding: '0.5rem',
  '&:hover': {
    opacity: 0.9,
  },
}));

function Index1() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${_1920_1080_3})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex flex-col items-center justify-center w-4/5 p-2 bg-black rounded-lg shadow-lg md:w-1/2 xl:w-1/3 sm:p-6 bg-opacity-90">
        {/* Main Title */}
        <h1 className="gap-5 text-xl font-bold text-center text-green-400 md:text-2xl font-ganh">
          ĐIÊN NẶNG #4 :
        </h1>
        <h1 className="my-4 text-5xl text-green-400 md:text-6xl font-ganh sm:mb-8">ĐIÊN DẠI</h1>
        
        {/* Poster Image */}
        <div className="flex justify-center w-full gap-4 mb-4">
          <div className="w-full lg:w-full xl:w-4/5">
            <Image 
              preview={false} 
              src={kvchinh} 
              className="w-full rounded-md shadow-md"
              alt="Poster"
            />
          </div>

          {/* <div className="hidden space-x-1 font-ganh font-sm sm:w-2/3 sm:display-none xl:block">
            <h2 className="font-ganh">Điên Dại - Điên Nặng 4</h2>
            <p className="font-ganh">
              Vượt qua bão Yagi, đến lượt siêu bão Điên Nặng 4, nhạc giật cấp 100. Điên Nặng lần này có gì khác? Chính là âm nhạc! 
              Vẫn là Underground nhưng lại có nhiều màu sắc mới mẻ, đầy đủ từ Hiphop, Các loại House, UKG, Bass Music, Techno, thậm chí là PsyTrance,... trộn lẫn vào với nhau.
            </p>
            <p className="font-ganh">
              Vẫn là tiêu chí Private Party, Chỉ có khách mời và Limited duy nhất 150 tickets mở bán tại bữa tiệc dành cho Người Điên.
            </p>
            <p className="font-ganh">
              Với 21+ Nghệ sĩ, từ Hà Nội và Sài Gòn, gồm cả DJ, Rappers, MC, cùng hàng trăm người điên khác, đây sẽ là bữa tiệc lớn nhất của Điên Nặng từ trước đến nay. 
              7 tiếng liên tục Party cùng đa dạng các thể loại âm nhạc miên, điểm đặc biệt tại số Điên Nặng lần này là: cùng điên, thoải mái loạn, miễn vui là được 👌.
            </p>
            <p className="font-bold text-green-500 font-ganh">Đã tới Điên Nặng là nhớ không được lái xe!</p>

            <h3 className="mt-2 font-ganh">Artist Lineup</h3>
            <p className="font-ganh"><strong className="font-ganh">DJ:</strong> C'Six, JxstZen, Tân Thiếu Gia, Orriganery, Juz Kevin, Steji, Luniic, Darksize, Lupin B, Titdayungboi, Dr. Mai Đích Loong, Kydz, YungCell</p>
            <p className="font-ganh"><strong className="font-ganh">MC:</strong> Unixerse, Bryan</p>
            <p className="font-ganh"><strong className="font-ganh">Rapper:</strong> CCMK, Cậu Phát, Sixtyuptown, SpideyBoy, Adam The Litder, TONIX</p>

            <h3 className="mt-2 font-ganh">Thông tin vé</h3>
            <p className="font-ganh"><strong className="font-ganh">GA Tickets:</strong> 250k/vé</p>

            <h3 className="mt-2">Về Điên Nặng 4</h3>
            <ul>
              <li className="font-ganh"><strong className="font-ganh">Thời gian:</strong> Thứ năm, 26. 9. 2024 - 21h00 đến khi điên.</li>
              <li className="font-ganh"><strong className="font-ganh">Địa điểm:</strong> Entrophy - 15A Chợ Gạo, Hoàn Kiếm, Hà Nội.</li>
              <li className="font-ganh"><strong className="font-ganh">Dresscode:</strong> Mặc gì trông điên là được 👌</li>
            </ul>
          </div> */}
        </div>

        {/* Info Button that triggers the modal */}
        {/* <div className="block w-full xl:hidden sm:w-3/4">
          <InfoButton
            variant="outlined"
            onClick={showModal}
            className="w-full main-button"
            >
            Thông tin chi tiết
          </InfoButton>
        </div> */}

        {/* Purchase Button */}
        <GreenButton
          variant="contained"
          href="/info"
          className="w-full sm:w-3/4 main-button"
          disabled
        >
          HẸN GẶP BẠN Ở ĐIÊN NẶNG PARTY 😈
        </GreenButton>

        <Modal title="" visible={isModalVisible} onOk={handleOk} className="custom-modal" style={{backgroundColor: 'black'}} 
          footer={[
            <GreenButton key="submit" type="default" onClick={handleOk}>
              OK
            </GreenButton>,
        ]}>
          <div className="w-full text-white bg-black">
            <h2 className="font-ganh">Điên Dại - Điên Nặng 4</h2>
            <p className="font-ganh">
              Vượt qua bão Yagi, đến lượt siêu bão Điên Nặng 4, nhạc giật cấp 100. Điên Nặng lần này có gì khác? Chính là âm nhạc! 
              Vẫn là Underground nhưng lại có nhiều màu sắc mới mẻ, đầy đủ từ Hiphop, Các loại House, UKG, Bass Music, Techno, thậm chí là PsyTrance,... trộn lẫn vào với nhau.
            </p>
            <p className="font-ganh">
              Vẫn là tiêu chí Private Party, Chỉ có khách mời và Limited duy nhất 150 tickets mở bán tại bữa tiệc dành cho Người Điên.
            </p>
            <p className="font-ganh">
              Với 21+ Nghệ sĩ, từ Hà Nội và Sài Gòn, gồm cả DJ, Rappers, MC, cùng hàng trăm người điên khác, đây sẽ là bữa tiệc lớn nhất của Điên Nặng từ trước đến nay. 
              7 tiếng liên tục Party cùng đa dạng các thể loại âm nhạc miên, điểm đặc biệt tại số Điên Nặng lần này là: cùng điên, thoải mái loạn, miễn vui là được 👌.
            </p>
            <p className="font-bold text-green-500 font-ganh">Đã tới Điên Nặng là nhớ không được lái xe!</p>

            <h3 className="mt-2 font-ganh">Artist Lineup</h3>
            <p className="font-ganh"><strong className="font-ganh">DJ:</strong> C'Six, JxstZen, Tân Thiếu Gia, Orriganery, Juz Kevin, Steji, Luniic, Darksize, Lupin B, Titdayungboi, Dr. Mai Đích Loong, Kydz, YungCell</p>
            <p className="font-ganh"><strong className="font-ganh">MC:</strong> Unixerse, Bryan</p>
            <p className="font-ganh"><strong className="font-ganh">Rapper:</strong> CCMK, Cậu Phát, Sixtyuptown, SpideyBoy, Adam The Litder, TONIX</p>

            <h3 className="mt-2 font-ganh">Thông tin vé</h3>
            <p className="font-ganh"><strong className="font-ganh">GA Tickets:</strong> 399k/vé</p>

            <h3 className="mt-2">Về Điên Nặng 4</h3>
            <ul>
              <li className="font-ganh"><strong className="font-ganh">Thời gian:</strong> Thứ năm, 26. 9. 2024 - 20h30 đến khi điên.</li>
              <li className="font-ganh"><strong className="font-ganh">Địa điểm:</strong> Entrophy - 15A Chợ Gạo, Hoàn Kiếm, Hà Nội.</li>
              <li className="font-ganh"><strong className="font-ganh">Dresscode:</strong> Mặc gì trông điên là được 👌</li>
            </ul>
          </div>
        </Modal>

        {/* Preloader */}
        <Preloader />
      </div>
    </div>
  );
}

export default Index1;
