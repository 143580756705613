import { createTheme } from "@mui/material";
import { green } from "@mui/material/colors";

// Light Theme
export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: green["A100"],
    },
    secondary: {
      main: green["A400"],
    },
    text: {
      primary: '#ffffff',
      secondary: green[100],
    },
    button: {
      active: "#F2F2F2",
    },
    background: {
      topBar: "#ffffff",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: green["A100"], // Primary color for the border
            },
            "&:hover fieldset": {
              borderColor: green["A400"], // Secondary color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: green["A100"], // Primary color when focused
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#ffffff', // Primary text color for input
        },
      },
    },
  },
});

// Dark Theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: green["A100"],
    },
    secondary: {
      main: green["A400"],
    },
    background: {
      topBar: "#242526",
    },
    button: {
      active: "#2F3031",
    },
    text: {
      primary: "#ffffff",
      secondary: green[500],
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: green["A100"], // Primary color for the border in dark theme
            },
            "&:hover fieldset": {
              borderColor: green["A400"], // Secondary color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: green["A100"], // Primary color when focused
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: "#ffffff", // Primary text color for input in dark theme
        },
      },
    },
  },
});
