import axios from "axios";

export async function createPaymentLink(formData) {
  console.log(`${process.env.REACT_APP_ORDER_URL}/api/order/createTicketPurchase`)
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_ORDER_URL}/api/order/createTicketPurchase`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getListBank() {
  try {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_LISTS_BANK_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}
export async function getOrder(orderId) {
  try {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_ORDER_URL}/api/order/${orderId}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}
export async function cancelOrder(orderId) {
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_ORDER_URL}/order/${orderId}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getCount() {
  try {
    const res = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_ORDER_URL}/count`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
}
// export async function createTicketPurchase(ticketData) {
//   try {
//     const res = await axios({
//       method: "POST",
//       url: "http://localhost:3000/api/order/createTicketPurchase",
//       data: ticketData,
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return res.data;
//   } catch (error) {
//     return error.response.data;
//   }
// }