import React, { Component } from "react";
// import visual600 from '../../static/images/visual600.mp4';
import visual600 from '../../static/images/visual600.gif';
import "./Preloader.css";

class Preloader extends Component {
  preloader() {
    let preload = document.querySelector(".preloader");
    setTimeout(() => {
      preload.style.opacity = "0";
      setTimeout(() => {
        preload.style.display = "none";
      }, 1000);
    }, 3000);
  }

  componentDidMount() {
    this.preloader();
  }

  render() {
    return (
      <div className="preloader">
        {/* <div className="image_wrap">
          <video autoPlay muted loop>
            <source src={visual600} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
        <div className="image_wrap">
          <img src={visual600} alt="Animated visual" />
        </div>
      </div>
    );
  }
}

export default Preloader;
