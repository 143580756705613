import React from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "GanhThin",
  letterSpacing: "1px",
  color: "#c8e6c9",
  borderBottom: "1px solid #4caf50", // Green border
  padding: "16px",
  '&:last-child': {
    borderRight: 'none',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: "black",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  width: "100%", // Full width for mobile
  maxWidth: "none", // Remove max-width constraint
  margin: "0 auto", // Center the table
  [theme.breakpoints.up("sm")]: {
    width: "75%", // 3/4 width for tablet and up
  },
}));

const StyledTable = styled(Table)({
  borderCollapse: "separate",
  borderSpacing: 0,
});

const ResponsiveBox = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: "24px",
}));

function formatDate(dateString) {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
}

export default function OrderTableDemo({
  data,
  existingTransaction,
  ticketPurchase,
}) {
  return (
    <ResponsiveBox>
      <Typography variant="h4" sx={{ fontFamily: "GanhThin", fontWeight: "bold", textAlign: "center", color: "black", marginBottom: "24px" }}>
        Đơn hàng{" "}
        <Box component="span" sx={{ fontFamily: "GanhThin", fontWeight: "bold", fontSize: "1em" }}>
          {data?.orderCode ? `#${data.orderCode}` : "không tìm thấy"}
        </Box>
        <Box component="span" sx={{ display: "block", fontSize: "0.8em", marginTop: "8px", fontFamily: "GanhThin", fontWeight: "bold" }}>
          {data?.status
            ? data.status === "PAID"
              ? "đã thanh toán thành công"
              : data.status === "CANCELLED"
              ? "đã hủy"
              : "chưa được thanh toán"
            : ""}
        </Box>
      </Typography>

      <StyledTableContainer component={Paper}>
        <Typography variant="h5" sx={{ fontFamily: "GanhThin", padding: "16px", textAlign: "center", color: "#c8e6c9", fontWeight: "bold" }}>
          Thông tin đơn hàng
        </Typography>
        <StyledTable>
          <TableBody>
            {data ? (
              <>
                <TableRow>
                  <StyledTableCell>Tên khách hàng</StyledTableCell>
                  <StyledTableCell>{ticketPurchase?.name || "N/A"}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>{ticketPurchase?.email || "N/A"}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Số điện thoại</StyledTableCell>
                  <StyledTableCell>{ticketPurchase?.phone || "N/A"}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Ngày sinh</StyledTableCell>
                  <StyledTableCell>
                    {ticketPurchase?.birthDate ? formatDate(ticketPurchase.birthDate) : "N/A"}
                  </StyledTableCell>
                </TableRow>
                {/* <TableRow>
                  <StyledTableCell>Giới tính</StyledTableCell>
                  <StyledTableCell>{ticketPurchase?.sex || "N/A"}</StyledTableCell>
                </TableRow> */}
                <TableRow>
                  <StyledTableCell>Số lượng vé</StyledTableCell>
                  <StyledTableCell>{ticketPurchase?.tickets || "N/A"}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Tình trạng</StyledTableCell>
                  <StyledTableCell>{data.status || "N/A"}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Nội dung thanh toán</StyledTableCell>
                  <StyledTableCell>{existingTransaction?.description || "N/A"}</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Tổng tiền</StyledTableCell>
                  <StyledTableCell>{data.amount || "N/A"} VNĐ</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>Mã đơn hàng</StyledTableCell>
                  <StyledTableCell>{existingTransaction?.orderCode || "N/A"}</StyledTableCell>
                </TableRow>
                {data.items && data.items.length > 0 && (
                  <TableRow>
                    <StyledTableCell>Chi tiết sản phẩm</StyledTableCell>
                    <StyledTableCell>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {data.items.map((item, index) => (
                          <li key={index} style={{ marginBottom: "8px" }}>
                            <strong>{item.name}</strong>
                            <br />
                            Số lượng: {item.quantity}, Đơn giá: {item.price} VNĐ
                          </li>
                        ))}
                      </ul>
                    </StyledTableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow>
                <StyledTableCell colSpan={2} sx={{ textAlign: "center" }}>
                  Không có thông tin đơn hàng
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </ResponsiveBox>
  );
}